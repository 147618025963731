import { CloseRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import ArrowButton from '@/pages/reservas/components/LightBox/arrowButton';

type GalleryProps = {
  isOpen: boolean;
  onClose: (() => void) | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<number>;
  images: {imagem: string}[]
};

export default function GalleryDetails({
  isOpen,
  onClose,
  currentIndex,
  setCurrentIndex,
  images
}: GalleryProps) {
  const [arrImages, setArrImages] = useState<ImagesListType>([]);

  useEffect(() => {
    if (images) {
      const newImages: ImagesListType = [];

      images.forEach((img) => {
        newImages.push({
          src: img.imagem,
          loading: 'lazy',
          alt: '',
          title: 'Premiação'
        });
      });
      // se vier no endpoint, alterar alt e title acima

      setArrImages(newImages);
    }
  }, []);

  const gotoPrevious = () =>
    currentIndex > 0 && setCurrentIndex(currentIndex - 1);

  const gotoNext = () =>
    currentIndex + 1 < arrImages.length && setCurrentIndex(currentIndex + 1);

  if (arrImages.length < 1) {
    return <></>;
  }

  return (
    <Lightbox
      currentIndex={currentIndex}
      isOpen={isOpen}
      onClose={onClose}
      images={arrImages}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      renderHeader={() => {
        return (
          <button
            onClick={onClose}
            style={{
              backgroundColor: 'rgba(0, 0,0, 0.8)',
              height: 50,
              width: 45,
              marginLeft: 'auto',
              marginRight: 8,
              marginTop: 8,
            }}
          >
            <CloseRounded style={{ fontSize: 32 }} />
          </button>
        );
      }}
      renderFooter={() => (
        <div style={{ textAlign: 'center', padding: 16, color: '#fff' }}>
          <h4 style={{ fontWeight: 700, color: '#fff' }}>
            {arrImages[currentIndex].title}
          </h4>
          <p style={{ color: '#fff' }}>{arrImages[currentIndex].alt}</p>
        </div>
      )}
      renderNextButton={() => (
        <ArrowButton
          position="right"
          onClick={gotoNext}
          disabled={currentIndex === arrImages.length - 1}
        />
      )}
      renderPrevButton={() => (
        <ArrowButton
          position="left"
          onClick={gotoPrevious}
          disabled={currentIndex === 0}
        />
      )}
    />
  );
}
