import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useTransition } from '@react-spring/web';
import {
  ArrowBack,
  ArrowBackIos,
  ArrowBackIosNew,
  ArrowBackIosNewSharp,
  ArrowBackOutlined,
  ArrowForwardIos,
} from '@mui/icons-material';

const ArrowButton = ({ position, onClick, disabled }: any) => {
  const transitions = useTransition(!disabled, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    (props, item) =>
      item && (
        <animated.div
          style={{
            ...props,
            zIndex: 999,
          }}
        >
          <button
            type="button"
            onClick={onClick}
            style={{
              position: 'absolute',
              left: position === 'left' ? 8 : 'unset',
              right: position === 'right' ? 8 : 'unset',
              height: 45,
              width: 35,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0,0, 0.8)',
            }}
          >
            {position === 'left' && (
              <ArrowForwardIos
                style={{ fontSize: 32, transform: 'scale(-1, -1)' }}
              />
            )}
            {position === 'right' && (
              <ArrowForwardIos style={{ fontSize: 32 }} />
            )}
          </button>
        </animated.div>
      )
  );
};

ArrowButton.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ArrowButton.defaultProps = {
  disabled: false,
};

export default ArrowButton;
