import Camera from "@/assets/svg/Camera";
import { useAppSelector } from "@/redux/hooks";
import { useState } from "react";
import GalleryDetails from "./GalleryDetails";

type Props = {
  
  premios: {
    imagem: string,
    produto: string
  }[]
}

export default function Rewards ({premios}: Props) {
  const { client } = useAppSelector((state) => state.authDelivery);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isOpenGallery, setOpenGallery] = useState(false);

  return (
    <>
      {client && premios && (
          <div>
          <p className="spaceTitle mt-4">
            <Camera /> Conheça nossos prêmios
          </p>
          <GalleryDetails
            isOpen={isOpenGallery}
            onClose={() => setOpenGallery(false)}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            images={premios}
          />

          <div className="spaceList">
            {premios.map((img, index) => {
               if (index < 6) {
                return (
                  <div
                    className="spaceItem"
                    style={{
                      backgroundImage: `url(${img.imagem})`,
                    }}
                    key={img.imagem + index}
                    onClick={() => {
                      setCurrentIndex(index);
                      setOpenGallery(true);
                    }}
                  >
                    {index === 5 &&
                      premios &&
                      premios.length > 6 && (
                        <span className="spaceItemMore">
                          +{premios?.length - 6}
                        </span>
                      )}
                  </div>
                );
              }
              
            })}
          </div>
        </div>)}
    </>
  )
}


