import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SEO from '@/helpers/seo';
import { NextPageContext } from 'next';
import HeadComp from '@/components/Head';
import UserStorage from '@/storages/user-storage';
import Header from '../Header';
import FidelityAccordion from '../FidelityAccordion/FidelityAccordion';
import FidelityAccordionItens from '../FidelityAccordion/FidelityAccordionItens';
import FidelityAccordionLocais from '../FidelityAccordion/FidelityAccordionLocais';
import Rewards from '../FidelityAccordion/Rewards';
import FidelityBadges from '../Badges';
import api from '@/services/cardapioFidelity/api';
import { CardapioPunctuationFidelity } from '@/services/cardapioFidelity/punctuation';
import { CardapioLocationFidelity } from '@/services/cardapioFidelity/location';
import { startFidelityLoading } from '@/redux/reducers/cardapioFidelity/cardapioPunctuationFidelity';
import { reset } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';

export default function Pontuacao({ seo }: any) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);
	const { response, loading } = useAppSelector(state => state.cardapioPunctuationFidelity);
	const { locationResponse } = useAppSelector(state => state.cardapioLocation);
	const [currentTab, setCurrentTab] = useState(0);
	const [userId, setUserId] = useState(0);
	useEffect(() => {
		if (client != null) {
			api.defaults.headers['API-TOKEN'] = `${client.token}`;
			setUserId(UserStorage.GetFidelityLogin()!);
		}
	}, [client]);

	useEffect(() => {
		dispatch(CardapioPunctuationFidelity(userId));
		dispatch(CardapioLocationFidelity(userId));
	}, [userId]);

	function changeTabs(id: number) {
		if (id === currentTab) {
			setCurrentTab(0);
		} else {
			setCurrentTab(id);
		}
	}

	function sair() {
		dispatch(reset());
	}

	const resgatar = async () => {
		dispatch(startFidelityLoading(true));
		dispatch(CardapioPunctuationFidelity(userId));
	};

	return (
		<>
			{seo && seo.retorno && seo.config_cliente && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}
			{!loading && client && client?.retorno ? (
				<section id="reservationPage" className="content">
					<div className="content">
						<Header />
						<div style={{ padding: '3.5rem 1rem 0 1rem' }}>
							<FidelityAccordion title="Regras do sistema de fidelidade" changeTabs={changeTabs} content={response?.regras!} currentTab={currentTab} order={1} />
							<FidelityAccordionItens title="Histórico de compras" changeTabs={changeTabs} content={response?.extrado_compras!} currentTab={currentTab} order={2} />
							{locationResponse && locationResponse.retorno ? <FidelityAccordionLocais title="Locais de retirada" changeTabs={changeTabs} content={locationResponse?.conteudo} currentTab={currentTab} order={3} /> : <></>}
							{!response?.usa_cashback && (
								<>
									<Rewards premios={response?.premios!} />
									<div className="pointsDiv">
										<p className="mainTitle">Pontuação:</p>
										<p className="pointsText">{response?.total_pontuacao!} Pontos</p>
									</div>
									<FidelityBadges pontos={response?.selos_pontuacao!} totalPontos={response?.selo_marcado!} />
								</>
							)}
							{response?.usa_cashback && (
								<>
									<div className="pointsDiv">
										<p className="mainTitle">Saldo Disponível:</p>
										<p className="pointsText">R$ {response?.desconto_disponivel ? response?.desconto_disponivel.toFixed(2) : '0,00'}</p>
									</div>
								</>
							)}
						</div>
					</div>
					<footer className="footer">
						{response?.usa_cashback && (
							<Button
								variant="contained"
								className="btnDefault mt-5"
								onClick={() => {
									resgatar();
								}}
								disabled={loading || response?.resgate_solicitado}>
								{response?.resgate_solicitado ? 'Resgate Solicitado' : 'Resgatar'}
							</Button>
						)}
						<Button
							variant="contained"
							className="btnDefault mt-5"
							onClick={() => {
								sair();
							}}
							disabled={loading}>
							Sair
						</Button>
					</footer>
				</section>
			) : (
				<div className="loadingDefault">
					<CircularProgress className="loading" />
				</div>
			)}
		</>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
