import BadgePlaceholder from "./components/BadgePlaceholder";


type Props = {
  pontos: number[];
  totalPontos: number;
}

export default function FidelityBadges ({pontos, totalPontos}: Props) {
  return (
    <div >
      <p className='mainTitle'>Selos</p>
      <div className='badgesPlaceholder'>
        {pontos?.map((badge)=> {
          return  <BadgePlaceholder key={badge} active={(totalPontos == badge ? true : false)} content={badge} />
        })}
      </div>
    </div>
  )
}