import ArrowIcon from "@/assets/svg/Arrow";
import { Button } from "@mui/material";

type Props = {
  title: string;
  content: {
    titulo: string;
    descricao: string;
  }[]
  currentTab: number
  changeTabs: Function
  order: number
}

export default function FidelityAccordionLocais({title, content, currentTab, changeTabs, order}: Props) {
return <div className="tabsItem">
            <Button
              variant="text"
              className="tabsHead"
              onClick={() => changeTabs(order)}
            >
              <span className="tabsTitle">
                {title}
              </span>
              <span
                style={{
                  transform:
                    currentTab === order ? `scale(-1, -1)` : 'none',
                  color: '#656572',
                }}
              >
                <ArrowIcon />
              </span>
            </Button>
            {currentTab === order && (
              <div className="tabsBody">
                {content && content!.map((item, index) => {
                  return (
                    
                <div key={index} style={{marginBottom:"10px"}}> 
                  <b dangerouslySetInnerHTML={{
                    __html: item.titulo, // popular com o dado do servidor
                  }} />
                  <p key={index}
                  className="tabsTxt"
                  dangerouslySetInnerHTML={{
                    __html: item.descricao, // popular com o dado do servidor
                  }}
                />
                </div>
                )
              })
            }
              </div>
              
            )}
          </div>

}