import React from 'react';

export default function Camera() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              d="M10 17C7.79 17 6 15.21 6 13C6 10.79 7.79 9 10 9C12.21 9 14 10.79 14 13C14 15.21 12.21 17 10 17ZM10 10C8.35 10 7 11.35 7 13C7 14.65 8.35 16 10 16C11.65 16 13 14.65 13 13C13 11.35 11.65 10 10 10Z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M18.5 10H16.5C16.22 10 16 9.78 16 9.5C16 9.22 16.22 9 16.5 9H18.5C18.78 9 19 9.22 19 9.5C19 9.78 18.78 10 18.5 10Z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M19.5 20H4.5C3.67 20 3 19.33 3 18.5V7.5C3 6.67 3.67 6 4.5 6H19.5C20.33 6 21 6.67 21 7.5V18.5C21 19.33 20.33 20 19.5 20ZM4.5 7C4.22 7 4 7.22 4 7.5V18.5C4 18.78 4.22 19 4.5 19H19.5C19.78 19 20 18.78 20 18.5V7.5C20 7.22 19.78 7 19.5 7H4.5Z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M8.5 7C8.22 7 8 6.78 8 6.5V6H6V6.5C6 6.78 5.78 7 5.5 7C5.22 7 5 6.78 5 6.5V5.5C5 5.22 5.22 5 5.5 5H8.5C8.78 5 9 5.22 9 5.5V6.5C9 6.78 8.78 7 8.5 7Z"
              fill="currentColor"
            />
          </g>
          <g>
            <path
              d="M18.5 7C18.22 7 18 6.78 18 6.5C18 6.78 17.78 7 17.5 7C17.22 7 17 6.78 17 6.5V5.5C17 5.22 17.22 5 17.5 5H18.5C18.78 5 19 5.22 19 5.5V6.5C19 6.78 18.78 7 18.5 7Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
